<script setup lang="ts">
import { computed, ref } from "vue";

import useSurfblasterState from "../composables/useSurfblasterState";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const inputValue = ref("");
const commandSelected = ref(-1);
const { commandHistory, sendCommand } = useSurfblasterState();

const handleInput = ({ target }) => {
  inputValue.value = target.value;
};

const handleCommand = () => {
  sendCommand(inputValue.value);
  inputValue.value = "";
  commandSelected.value = -1;
};

const nextCommand = () => {
  if (hasNotReachedLastCommandInHistory()) commandSelected.value++;
  inputValue.value = commandHistory.value[commandSelected.value];
};

const lastCommand = () => {
  if (hasReachedBeginningOfCommandHistory()) {
    inputValue.value = "";
    return;
  }

  commandSelected.value--;
  inputValue.value = commandHistory.value[commandSelected.value];
};

const hasNotReachedLastCommandInHistory = () => {
  return commandSelected.value < commandHistory.value.length - 1;
};

const hasReachedBeginningOfCommandHistory = () => {
  return commandSelected.value === -1;
};
</script>

<template>
  <telia-text-input
    t-id="commands-input"
    @input="handleInput"
    @keydown.enter.prevent="handleCommand"
    @keydown.up.prevent="nextCommand"
    @keydown.down.prevent="lastCommand"
    :value="inputValue"
    :label="t('mybusiness.surfblaster.input.label')"
  />
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables";

telia-text-input :deep(input) {
  background-color: $telia-black;
  color: $telia-white;
}
</style>
