import { corpCustomerSurfblaster } from "@telia/b2b-rest-client";
import { TerminalOutputLine } from "../composables/interfaces/IState";

export const connectBe = (scopeId: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    corpCustomerSurfblaster.CorpCustomerSurfBlasterService.connect(scopeId)
      .then(() => resolve(true))
      .catch((error) => reject(error));
  });
};

export const disconnectBe = (scopeId: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    corpCustomerSurfblaster.CorpCustomerSurfBlasterService.disconnect(scopeId)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const pollBe = (scopeId: string): Promise<TerminalOutputLine[]> => {
  return new Promise<TerminalOutputLine[]>((resolve, reject) => {
    corpCustomerSurfblaster.CorpCustomerSurfBlasterService.poll(scopeId)
      .then((response) => resolve(_mapTerminalOutputLine(response)))
      .catch((error) => reject(error));
  });
};

export const getConnectionStatusToBeBeforeSendingReq = (scopeId: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    return corpCustomerSurfblaster.CorpCustomerSurfBlasterService.getConnectionStatusBeforeSendingReq(
      scopeId
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const sendToBe = (
  scopeId: string,
  command: corpCustomerSurfblaster.CommandDTO
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    return corpCustomerSurfblaster.CorpCustomerSurfBlasterService.send(scopeId, command)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const _mapTerminalOutputLine = (
  response: corpCustomerSurfblaster.TerminalOutputLineDTO[]
): TerminalOutputLine[] => {
  // @ts-expect-error BE service needs to update type
  if (response.output) {
    // @ts-expect-error BE service needs to update type
    return response.output.map((item) => ({
      line: item.line || "",
      inputEnabled: item.inputEnabled || false,
      translationRequired: item.translationRequired || false,
    }));
  } else {
    return response.map((item) => ({
      line: item.line || "",
      inputEnabled: item.inputEnabled || false,
      translationRequired: item.translationRequired || false,
    }));
  }
};
