import { Layout, TerminalOutputLine, SurfblasterError } from "./interfaces/IState";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import {
  action,
  category as Category,
  trackEvent as TrackEvent,
} from "@telia/b2b-web-analytics-wrapper";
import { ref } from "vue";
import {
  connectBe,
  getConnectionStatusToBeBeforeSendingReq,
  pollBe,
  disconnectBe,
  sendToBe,
} from "../services/corp-customer-surfblaster";
import { logError as LogError } from "@telia/b2x-logging";

const layout = ref<Layout>({
  loading: false,
  error: false,
  errorType: SurfblasterError.NONE,
});
const isConnected = ref(false);
const scopeId = ref("");
const messages = ref<string[]>([]);
const commandHistory = ref<string[]>([]);

const pollConnectionStatusTimeout = ref<ReturnType<typeof setTimeout> | undefined>();
const pollForMessagesTimeout = ref<ReturnType<typeof setTimeout> | undefined>();

const useSurfblasterState = () => {
  const initialize = async () => {
    scopeId.value = await getScopeIdOrThrow();

    trackEvent("Surfblaster terminalen startades av en användare.", action.OPEN);

    createConnection();
  };

  const createConnection = async (): Promise<void> => {
    try {
      layout.value.error = false;
      layout.value.errorType = SurfblasterError.NONE;
      const response = await connectBe(scopeId.value);
      isConnected.value = response;
      if (!pollConnectionStatusTimeout.value) {
        pollConnectionStatusTimeout.value = setTimeout(pollConnectionStatus, 2000);
      }
      if (!pollForMessagesTimeout.value) {
        pollForMessagesTimeout.value = setTimeout(poll, 2000);
      }
    } catch (error) {
      logError("Failed to create connection");
      layout.value.error = true;
      layout.value.errorType = SurfblasterError.NETWORK;
    }
  };

  const disconnect = async (): Promise<void> => {
    try {
      clearPolls();
      await disconnectBe(scopeId.value);
      isConnected.value = false;
    } catch (error) {
      logError("Failed to disconnect");
    }
  };

  const clearMessages = (): void => {
    messages.value = [];
  };

  const sendCommand = async (command: string): Promise<void> => {
    try {
      layout.value.error = false;
      layout.value.errorType = SurfblasterError.NONE;

      clearPollConnectionStatus();
      await pollConnectionStatus();
      if (!isConnected.value) return;

      await sendToBe(scopeId.value, { command });
      addCommandToHistory(command);
      if (!pollForMessagesTimeout.value) {
        pollForMessagesTimeout.value = setTimeout(poll.bind, 2000);
      }
    } catch (error) {
      layout.value.error = true;
      layout.value.errorType = SurfblasterError.NETWORK;
    }
  };

  const pollConnectionStatus = async (): Promise<void> => {
    try {
      const status = await getConnectionStatusToBeBeforeSendingReq(scopeId.value);
      isConnected.value = status;

      if (!isConnected.value) {
        clearPolls();
        layout.value.error = true;
        layout.value.errorType = SurfblasterError.CONNECTION_DROPPED;
      } else {
        pollConnectionStatusTimeout.value = setTimeout(pollConnectionStatus, 2000);
      }
    } catch (error) {
      logError("Failed to get connection status");
    }
  };

  const checkBlacklisted = (key: string): boolean => {
    return [
      "SURFBLASTER_LOG_INFO_DISCONNECTED",
      "SURFBLASTER_LOG_INFO_CONNECTED",
      "SURFBLASTER_LOG_INFO_RECONNECTING",
      "SURFBLASTER_LOG_INFO_INACTIVITY",
      "SURFBLASTER_LOG_ERROR_CONNECTION_LOST",
      "SURFBLASTER_LOG_ERROR_INVALID_PERMISSION",
      "SURFBLASTER_LOG_INFO_DISCONNECTED",
    ].includes(key);
  };

  const parseCommandResponse = (data: TerminalOutputLine[]): string[] => {
    if (data.length === 0) return [];

    return data.reduce((memo: string[], item: TerminalOutputLine) => {
      const resultData = item.line;
      if (resultData) {
        if (!checkBlacklisted(resultData)) {
          memo.push(resultData.replace(/(\r\n|\n\r|\r|\n)/g, String.fromCharCode(13, 10)));
        }
      }
      return memo;
    }, []);
  };

  const poll = async (): Promise<void> => {
    try {
      const result = await pollBe(scopeId.value);
      messages.value.push(...parseCommandResponse(result));
      pollForMessagesTimeout.value = setTimeout(poll, 2000);
    } catch (error) {
      logError("Failed polling for messages");
    }
  };

  const clearPolls = () => {
    clearPollConnectionStatus();
    clearPollForMessages();
  };

  const clearPollForMessages = () => {
    clearTimeout(pollForMessagesTimeout.value);
    pollForMessagesTimeout.value = undefined;
  };

  const clearPollConnectionStatus = () => {
    clearTimeout(pollConnectionStatusTimeout.value);
    pollConnectionStatusTimeout.value = undefined;
  };

  const addCommandToHistory = (command: string): void => {
    commandHistory.value.push(command);
  };

  const trackEvent = (label: string, action: string, category = Category.SSO_SERVICES): void => {
    TrackEvent(category, action, label);
  };

  const logError = (message: string) => {
    LogError("b2b-surfblaster", message);
  };

  return {
    layout,
    isConnected,
    messages,
    commandHistory,
    pollConnectionStatusTimeout,
    pollForMessagesTimeout,
    initialize,
    createConnection,
    disconnect,
    clearMessages,
    sendCommand,
    pollConnectionStatus,
  };
};

export default useSurfblasterState;
