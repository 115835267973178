<script setup lang="ts">
import { computed } from "vue";

import useSurfblasterState from "../composables/useSurfblasterState";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { isConnected } = useSurfblasterState();

const iconName = computed(() => {
  return isConnected.value ? "check-circle-filled" : "error-filled";
});

const statusText = computed(() => {
  return isConnected.value ? t("mybusiness.connected") : t("mybusiness.disconnected");
});
</script>

<template>
  <div class="surfblaster-header">
    <telia-heading tag="h1">{{ t("mybusiness.surfblaster.header") }}</telia-heading>
    <div t-id="connection-status" class="connection-status">
      <div :class="[isConnected ? 'connected' : 'disconnected']">
        <telia-icon :name="iconName" size="md" />
      </div>
      <telia-p>{{ statusText }}</telia-p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables";

.surfblaster-header {
  display: flex;
  justify-content: space-between;
}

.connection-status {
  display: flex;
  align-items: center;
}

.connected {
  color: $telia-green-700;
}

.disconnected {
  color: $telia-orange-400;
}
</style>
