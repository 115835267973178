<script setup lang="ts">
import { computed } from "vue";

import useSurfblasterState from "../composables/useSurfblasterState";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { isConnected, createConnection, disconnect, clearMessages } = useSurfblasterState();
</script>

<template>
  <div class="buttons-wrapper">
    <telia-button
      t-id="connect-button"
      v-if="!isConnected"
      @click="createConnection"
      variant="primary"
      >{{ t("mybusiness.connect") }}</telia-button
    >
    <telia-button
      t-id="disconnect-button"
      v-if="isConnected"
      @click="disconnect"
      variant="primary"
      >{{ t("mybusiness.disconnect") }}</telia-button
    >
    <telia-button t-id="clear-output-button" @click="clearMessages" variant="secondary">{{
      t("mybusiness.clear")
    }}</telia-button>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;

  telia-button {
    padding-right: $telia-spacing-12;
  }
}
</style>
