<script setup lang="ts">
import { computed } from "vue";

import { SurfblasterError } from "../composables/interfaces/IState";
import useSurfblasterState from "../composables/useSurfblasterState";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { layout } = useSurfblasterState();

const displayNotification = computed(() => {
  return layout.value.error;
});

const errorType = computed(() => {
  return layout.value.errorType;
});

const heading = computed(() => {
  switch (errorType.value) {
    case SurfblasterError.NETWORK:
      return t("networkErrorHeader");
    case SurfblasterError.CONNECTION_DROPPED:
      return t("connectionDroppedHeader");
    default:
      return "";
  }
});

const content = computed(() => {
  switch (errorType.value) {
    case SurfblasterError.NETWORK:
      return t("networkErrorMessage");
    case SurfblasterError.CONNECTION_DROPPED:
      return t("connectionDroppedMessage");
    default:
      return "";
  }
});
</script>

<template>
  <div>
    <Transition name="notification">
      <div class="notification" v-if="displayNotification">
        <telia-notification status="error" heading-tag="h2">
          <span slot="heading">{{ heading }}</span>
          <telia-text-spacing slot="content">
            <telia-p>{{ content }}</telia-p>
          </telia-text-spacing>
        </telia-notification>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.notification {
  width: 100%;
  padding-top: $telia-spacing-8;
  padding-bottom: $telia-spacing-32;
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 1.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
}
</style>
