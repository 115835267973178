<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { translateSetup } from "./locale";

import Buttons from "./components/buttons.vue";
import CommandsInput from "./components/commands-input.vue";
import TextOutputArea from "./components/text-output-area.vue";
import ErrorNotification from "./components/error-notification.vue";
import SurfblasterHeader from "./components/surfblaster-header.vue";
import useSurfblasterState from "./composables/useSurfblasterState";

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  reRenderKey.value++;
  initialize();
});

const reRenderKey = ref(0);
const { initialize } = useSurfblasterState();
</script>

<template>
  <div t-id="b2b-surfblaster-page" class="surfblaster-page" :key="reRenderKey">
    <telia-grid>
      <surfblaster-header />
      <text-output-area class="textOutputArea--component" />
      <commands-input class="commands-input--component" />
      <div class="buttons--container">
        <error-notification />
        <buttons />
      </div>
    </telia-grid>
  </div>
</template>

<style lang="scss">
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/colors/variables";

.surfblaster-page {
  margin-top: $telia-spacing-32;
}

.textOutputArea--component {
  margin-top: $telia-spacing-8;
  min-height: 50rem;
  max-height: 50rem;
  min-width: 100%;
  background-color: $telia-black;
}

.commands-input--component {
  margin-top: $telia-spacing-16;
}

.buttons--container {
  margin-top: $telia-spacing-16;
  margin-bottom: $telia-spacing-16;
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    width: 50%;
  }
}
</style>
