export interface Layout {
  loading: boolean;
  error: boolean;
  errorType: SurfblasterError;
}

export interface TerminalOutputLine {
  line: string;
  inputEnabled: boolean;
  translationRequired: boolean;
}

export enum SurfblasterError {
  NETWORK = "NETWORK",
  CONNECTION_DROPPED = "CONNECTION_DROPPED",
  NONE = "NONE",
}
