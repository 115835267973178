<script setup lang="ts">
import { computed, ref, watch } from "vue";

import useSurfblasterState from "../composables/useSurfblasterState";

const textarea = ref<HTMLTextAreaElement | undefined>();
const { messages } = useSurfblasterState();

const message = computed(() => {
  return messages.value.join("");
});

watch(
  () => message.value,
  () => {
    setTimeout(() => {
      if (!textarea.value) return;
      textarea.value.scrollTop = textarea.value.scrollHeight;
    }, 10);
  }
);
</script>

<template>
  <textarea
    ref="textarea"
    class="text-output-area"
    name="textArea"
    t-id="text-output-area"
    readonly
    :value="message"
  ></textarea>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables";

.text-output-area {
  color: $telia-white;
}
</style>
